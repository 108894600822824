import { Component } from "react";
import '../css/data-query.css';
import { CustomerContent } from "../utils/data-types";
import { groupByDate, groupByCustomerTask } from "./employee";

type CustomerData = {
    customerData: CustomerContent,
    customerNames: string[]
}

type CustomerQuery = {
    customer?: string;
    startDate?: string;
    endDate?: string;
    display?: boolean;
}

function displayCustomerData(customerName, tasksHeaders, data) {
    return <div className="customer-data">
        <div className="employeeData">
            <div className="employeeName">{customerName}</div>
            <div className="views">
                <div className='montly-view'>
                    <table className="margin-around">
                        <thead>
                            <tr>
                                <td></td>
                                {tasksHeaders.map(e => <td>{e}</td>)}
                            </tr>
                        </thead>
                        <tbody>
                            {data}
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
}

type CustomerState = {
    monthly: any[]; 
    customerName?: string; 
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
}

export class Customers extends Component<CustomerData, CustomerState> {

    constructor(props) {
        super(props);
        let perviousMonth = new Date().getMonth() -3;
        let previousYear = new Date().getFullYear();
        if (perviousMonth < 0){
            perviousMonth += 13;
            previousYear -= 1;
        }else {
            perviousMonth += 1;
        }

        let previousMonthString = perviousMonth.toString();
        if (previousMonthString.length === 1) {
            previousMonthString = "0" + previousMonthString;
        }
        
        let currentMonth = (new Date().getMonth() + 1).toString();
        if (currentMonth.length === 1) {
            currentMonth = "0" + currentMonth;
        }
        const currentYear = new Date().getFullYear();


        this.state = { monthly: [], startMonth: previousMonthString, startYear: previousYear.toString(), endMonth: currentMonth, endYear:currentYear.toString(),};
        this.getCustomerData = this.getCustomerData.bind(this);
        this.updateStartMonth = this.updateStartMonth.bind(this);
        this.updateStartYear = this.updateStartYear.bind(this);
        this.updateEndMonth = this.updateEndMonth.bind(this);
        this.updateEndYear = this.updateEndYear.bind(this);

    }

    getCustomerData() {
        const startDate = (document.getElementById("start-date") as HTMLSelectElement);
        const startYear = (document.getElementById("start-year") as HTMLSelectElement);
        const endDate = (document.getElementById("end-date") as HTMLSelectElement);
        const endYear = (document.getElementById("end-year") as HTMLSelectElement);
        const nameOption = (document.getElementById("customer-name") as HTMLSelectElement);
        const temp: CustomerQuery = {
            customer: nameOption.options[nameOption.selectedIndex].value,
            startDate: startYear.options[startYear.selectedIndex].value + "-" + startDate.options[startDate.selectedIndex].value + "-01",
            endDate: endYear.options[endYear.selectedIndex].value + "-" + endDate.options[endDate.selectedIndex].value + "-01",
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset="utf-8"' },
            body: JSON.stringify(temp),
        };
        fetch('server/get-customer-tasks.php', requestOptions)
            .then(response => response.json())
            .then(data => this.displayCustomerData(data) );
    }

    displayCustomerData(data) {
        const customers: any[] = [];
        for (const customerData in data){
            if (data[customerData]["tasks"] !== undefined){
                const dated = groupByDate(data[customerData]['tasks']);
                const customerTasksPerMonth = {name: data[customerData]["name"], tasks:{}}
                for (const key in dated) {
                    if (key && key !== null && key !== 'null') {
                        customerTasksPerMonth.tasks[key] = groupByCustomerTask(dated[key]);
                    }
                }
                customers.push(customerTasksPerMonth);
            }
        }
        this.setState({ monthly: customers });
    }


    updateStartMonth(event) {
        var newState: CustomerState = { 
            monthly: this.state.monthly, 
            startMonth: event.target.value,
            startYear: this.state.startYear,
            endMonth:this.state.endMonth, 
            endYear: this.state.endYear
        };
        this.setState(newState);
    }

    updateStartYear(event) {
        var newState: CustomerState = {
            monthly: this.state.monthly,
            startMonth: this.state.startMonth,
            startYear: event.target.value,
            endMonth: this.state.endMonth,
            endYear: this.state.endYear
        };
        this.setState(newState);
    }

    updateEndMonth(event){
        var newState: CustomerState = {
            monthly: this.state.monthly, 
            startMonth: this.state.startMonth,
            startYear: this.state.startYear,
            endMonth: event.target.value, 
            endYear: this.state.endYear
        };
        this.setState(newState);
    }

    updateEndYear(event) {
        var newState: CustomerState = {
            monthly: this.state.monthly,
            startMonth: this.state.startMonth,
            startYear: this.state.startYear,
            endMonth: this.state.endMonth,
            endYear: event.target.value
        };
        this.setState(newState);
    }

    public render() {
        const data = {};

        const headers = new Set<string>();
        this.state.monthly.map(monthly => {
            for (const month in monthly.tasks) {
                for (const task in monthly.tasks[month]) {
                    headers.add(task);
                }
            }
        });
        
        const tasksHeaders = Array.from(headers.values()).sort();

        this.state.monthly.map(monthly => {
            data[monthly.name] = [];
            for (const month in monthly.tasks) {
                const monthlyData = [];
                for (const header in tasksHeaders) {
                    if (tasksHeaders[header] in monthly.tasks[month]) {
                        monthlyData.push(<td>{monthly.tasks[month][tasksHeaders[header]].reduce((a: any, b: any) => a + b, 0).toFixed(2)}</td>);
                    } else {
                        monthlyData.push(<td>-</td>);
                    }
                }
                data[monthly.name].push(<tr><td>{month}</td>{monthlyData}</tr>);
            }
        });


        const customerPrices = this.props.customerData.prices;
        const customerNames: string[] = [];
        // console.log(customerNames);
        customerNames.push(...this.props.customerNames);
        for (const customer in customerPrices) {
            let add = true;
            for (const c in customerNames){
                if (customerNames[c] === customer)
                {
                    add = false;
                    break;
                }   
            }
            if (add){
                customerNames.push(customer);
            }
        }

        
 
        // console.log("Month", currentDate.getMonth() + 1);

        return (<div className="data-query">
            <div className="customer-selection">
                <div>
                    <select id="customer-name">
                        <option key="all" value="all">Kaikki asiakkaat</option>
                        {customerNames.map((name) => { return (<option key={name} value={name}>{name}</option>); })}
                    </select>
                </div>
                <div className="dates">
                    <select id="start-date" value={this.state.startMonth} onChange={this.updateStartMonth}>
                        <option value="01">Tammikuu</option>
                        <option value="02">Helmikuu</option>
                        <option value="03">Maaliskuu</option>
                        <option value="04">Huhtikuu</option>
                        <option value="05">Toukokuu</option>
                        <option value="06">Kesäkuu</option>
                        <option value="07">Heinäkuu</option>
                        <option value="08">Elokuu</option>
                        <option value="09">Syyskuu</option>
                        <option value="10">Lokakuu</option>
                        <option value="11">Marraskuu</option>
                        <option value="12">Joulukuu</option>
                    </select>
                    <select id="start-year" value={this.state.startYear} onChange={this.updateStartYear}>
                        <option>2021</option>
                        <option>2022</option>
                        <option>2023</option>
                    </select>
                    <span>-</span>
                    <select id="end-date" value={this.state.endMonth} onChange={this.updateEndMonth}>
                        <option value="01">Tammikuu</option>
                        <option value="02">Helmikuu</option>
                        <option value="03">Maaliskuu</option>
                        <option value="04">Huhtikuu</option>
                        <option value="05">Toukokuu</option>
                        <option value="06">Kesäkuu</option>
                        <option value="07">Heinäkuu</option>
                        <option value="08">Elokuu</option>
                        <option value="09">Syyskuu</option>
                        <option value="10">Lokakuu</option>
                        <option value="11">Marraskuu</option>
                        <option value="12">Joulukuu</option>
                    </select>
                    <select id="end-year" value={this.state.endYear} onChange={this.updateEndYear}>
                        <option>2021</option>
                        <option>2022</option>
                        <option>2023</option>
                    </select>
                </div>
                <div>
                    <button onClick={this.getCustomerData} className="search">Näytä</button>
                </div>
            </div>

            {this.state.monthly.map(customer=>displayCustomerData(customer.name, tasksHeaders, data[customer.name]))}
        </div>)
    }
}
