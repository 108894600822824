import './css/monthly-view.css'
import { CustomerProps, CustomersPrices, MonthlyPricing } from './pages/customer-prices';
import { EmployeeMontlyCosts } from './pages/employee';
import { Tasks, MonthlyTasks, CustomerPrices } from './utils/data-types';

function formatValue(value: number) {
    return !isNaN(value) && value % 1 === 0 ? value : value.toFixed(2);
}

export function generateMonthlyData(employeData: Tasks, employeeName: string, index: number, customerPrices: CustomerPrices) {
    let tableContent = [];
    let headers: string[] = [];
    let total = 0;
    let headerSums = {};
    for (const [customerName, customerData] of Object.entries(employeData.customer!)) {
        headers.push(...Object.keys(customerData.grouped));
    }
    const tableHeaders = [<th key={"he" + employeeName + index}>Asiakas</th>];
    headers = headers.filter((v, i, a) => a.findIndex(t => (t === v)) === i).sort();
    headers.map(h => tableHeaders.push(<th key={"h" + h + employeeName + index}>{h}</th>));
    tableHeaders.push(<th> Tulot yhteensä</th>);
    for (const customer in employeData.customer) {
        const talbeRow = [<td key={"cname" + customer + employeeName} className='customer-name'>{customer}</td>];
        let customerProduction = 0;
        headers.map(header => {

            if (header in headerSums){
                headerSums[header] += header in employeData.customer[customer].grouped
                    ? formatValue(employeData.customer[customer].grouped[header].reduce((a: any, b: any) => a + b, 0))
                    : 0
            }else {
                headerSums[header] = header in employeData.customer[customer].grouped
                    ? formatValue(employeData.customer[customer].grouped[header].reduce((a: any, b: any) => a + b, 0))
                    : 0
            }
            
            if (customer in customerPrices){
                if (header in customerPrices[customer] && employeData.customer[customer].grouped[header]){
                    const sum = employeData.customer[customer].grouped[header].reduce((a: any, b: any) => a + b, 0);
                    customerProduction += sum * customerPrices[customer][header];
                }
            }


            talbeRow.push(<td key={"t" + header + customer + employeeName}>
                {header in employeData.customer[customer].grouped
                    ? formatValue(employeData.customer[customer].grouped[header].reduce((a: any, b: any) => a + b, 0))
                    : "-"}</td>);

        });
        talbeRow.push(<td className='profit'>{customerProduction.toFixed(2)} € </td>);
        tableContent.push(<tr key={"tr" + employeeName + index + customer}>{talbeRow}</tr>);
        total += customerProduction;
    }
    return ({ headers: tableHeaders, content: tableContent, total, headerSums });
}

export function Months(monthlys: MonthlyTasks,
     employeeName: string,
     index: number,
     customerPrices: CustomerPrices,
     pricing: MonthlyPricing,
     removeTaskIds: number[],
     monthlyCosts?: EmployeeMontlyCosts[],
     updateMontlyCosts?: CallableFunction, 
     removeTask?: CallableFunction) {
    const result = [];
    for (const month in monthlys) {
        result.push(MonthlyView(monthlys[month], employeeName, index, month, customerPrices, pricing, removeTaskIds, monthlyCosts, updateMontlyCosts, removeTask));
    }
    return result;
}

function dateToReadable(date: string) {
    const n = date.split(".");
    let res = "";
    switch (n[0]) {
        case "1":
        case "01":
            res += "Tammikuu ";
            break;
        case "2":
        case "02":
            res += "Helmikuu ";
            break;
        case "3":
        case "03":
            res += "Maaliskuu ";
            break;
        case "4":
        case "04":
            res += "Huhtikuu ";
            break;
        case "5":
        case "05":
            res += "Toukokuu ";
            break;
        case "6":
        case "06":
            res += "Kesäkuu ";
            break;
        case "7":
        case "07":
            res += "Heinäkuu ";
            break;
        case "8":
        case "08":
            res += "Elokuu ";
            break;
        case "9":
        case "09":
            res += "Syyskuu ";
            break;
        case "10":
            res += "Lokakuu ";
            break;
        case "11":
            res += "Marraskuu ";
            break;
        case "12":
            res += "Joulukuu ";
            break;
        default:
            res = undefined;
    }
    return res ? res += n[1] : undefined;
}

export function DisplayMontlyCosts(employeeName: string, date: string, updateMontlyCosts: CallableFunction, pricing: MonthlyPricing, monthlyCosts: EmployeeMontlyCosts[], totalGains: number){
    const parsedDate = (date.split('.'))[1] + "-" + (date.split('.'))[0] + "-01"
    let data;
    for (const monthlys in monthlyCosts){
        if (parsedDate === monthlyCosts[monthlys].monthDate || parsedDate.indexOf("undefined") !== -1){
            data = monthlyCosts[monthlys];
            break;
        }
    }

    const totalCost = 
        (data ? parseFloat(data.kmCompanyCar) : 0) * parseFloat(pricing['kmCompanyCar'])+
        (data ? parseFloat(data.kmOwnCar) : 0) * parseFloat(pricing['kmOwnCar'])+
        (data ? parseFloat(data.halfDay) : 0) * parseFloat(pricing['halfDay'])+
        (data ? parseFloat(data.fullDay) : 0) * parseFloat(pricing['fullDay'])+
        (data ? parseFloat(data.monthlyHours) : 0) * parseFloat(pricing['monthlyHours'])+
        (data ? parseFloat(data.other) : 0);

    return <div className='custom-values'>
        <div className='value-header'>
            Erikseen syötettävä
        </div>
        <table>
            <thead>
                <tr>
                    <td>Km firman auto	(km)</td>
                    <td>Km oma auto (km)</td>
                    <td>Puolipäiväraha	(kpl)</td>
                    <td>Kokopäiväraha	(kpl)</td>
                    <td>Kuukauden työtunnit (h)</td>
                    <td>Muut (€)</td>
                    <td>Kulut yhteensä (€)</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><input className='user-input' value={data ? data.kmCompanyCar : 0} onChange={(e)=>updateMontlyCosts(employeeName, parsedDate, "kmCompanyCar", e.target.value)} disabled={parsedDate.indexOf("undefined") !== -1}/></td>
                    <td><input className='user-input' value={data ? data.kmOwnCar : 0} onChange={(e)=>updateMontlyCosts(employeeName, parsedDate, "kmOwnCar", e.target.value)} disabled={parsedDate.indexOf("undefined") !== -1}/></td>
                    <td><input className='user-input' value={data ? data.halfDay : 0} onChange={(e)=>updateMontlyCosts(employeeName, parsedDate, "halfDay", e.target.value)} disabled={parsedDate.indexOf("undefined") !== -1}/></td>
                    <td><input className='user-input' value={data ? data.fullDay : 0} onChange={(e)=>updateMontlyCosts(employeeName, parsedDate, "fullDay", e.target.value)} disabled={parsedDate.indexOf("undefined") !== -1}/></td>
                    <td><input className='user-input' value={data ? data.monthlyHours : 0} onChange={(e)=>updateMontlyCosts(employeeName, parsedDate, "monthlyHours", e.target.value)} disabled={parsedDate.indexOf("undefined") !== -1}/></td>
                    <td><input className='user-input' value={data ? data.other : 0} onChange={(e)=>updateMontlyCosts(employeeName, parsedDate, "other", e.target.value)} disabled={parsedDate.indexOf("undefined") !== -1}/></td>
                    <td className='cost'>{totalCost.toFixed(2)} €</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className={(totalGains - totalCost) >= 0 ? 'gain' : 'loss'}>{(totalGains-totalCost).toFixed(2)} €</td>
                </tr>
            </tbody>
        </table>
    </div>;
}

function toggleTasks(month, employeeName) {
    document.getElementById(month + employeeName).hidden = !document.getElementById(month + employeeName).hidden;
}

function generateTaskTable(tasks: Tasks, removeTask: CallableFunction, removeTaskIds: number[]) {
    const taskList = [];
    tasks.tasks.forEach(task => {
        const taskRow = [];
        taskRow.push(<td>{task['Tehtava']}</td>);
        taskRow.push(<td>{task['Tyotila']}</td>);
        taskRow.push(<td>{task['Rek.nro']}</td>);
        taskRow.push(<td>{task['Tyontekija']}</td>);
        taskRow.push(<td>{task['Asiakas']}</td>);
        taskRow.push(<td>{task['Seurantakohde']}</td>);
        taskRow.push(<td>{task['Alkoi']}</td>);
        taskRow.push(<td>{task['Paattyi']}</td>);
        taskRow.push(<td>{task['Kesto']}</td>);
        taskRow.push(<td>{task['Matka']}</td>);
        taskRow.push(<td>{task['Tuotteet']}</td>);
        taskRow.push(<td><button onClick={()=>removeTask(task['id'])}>Poista</button></td>);
        taskList.push(<tr className={removeTaskIds.indexOf(task['id']) !== -1 ? 'loss' : ''}>{taskRow}</tr>);
    });

    return taskList
}

export function MonthlyView(employeData: Tasks, employeeName: string, index: number, date: string, customerPrices: CustomerPrices, pricing: MonthlyPricing, removeTaskIds: number[], monthlyCosts?: EmployeeMontlyCosts[], updateMonthlyCosts?: CallableFunction, removeTask?: CallableFunction) {

    const data = generateMonthlyData(employeData, employeeName, index, customerPrices);
    const totalRow = [];
    for(const total in data.headerSums){
        totalRow.push(<td>{data.headerSums[total]}</td>);
    }
    return (<div className="montly-view">
        <div className="month">{dateToReadable(date) === undefined ? date : dateToReadable(date)} <button onClick={() => toggleTasks(dateToReadable(date) === undefined ? date : dateToReadable(date), employeeName)}> + </button></div>
        <div id={(dateToReadable(date) === undefined ? date : dateToReadable(date)) + employeeName} className='taskTable' hidden>
            <table>
                <thead>
                    <tr>
                        <td>Tehtava</td>
                        <td>Työtila</td>
                        <td>Rek.nro</td>
                        <td>Työntekija</td>
                        <td>Asiakas</td>
                        <td>Seurantakohde</td>
                        <td>Alkoi</td>
                        <td>Päättyi</td>
                        <td>Kesto</td>
                        <td>Matka</td>
                        <td>Tuotteet</td>
                        <td>Poista kirjaus</td>
                    </tr>
                </thead>
                <tbody>
                    {generateTaskTable(employeData, removeTask, removeTaskIds)}
                </tbody>
            </table>
        </div>
        <div id="monthly-content">
            {!!updateMonthlyCosts ? DisplayMontlyCosts(employeeName, date, updateMonthlyCosts, pricing, monthlyCosts, data.total) : ''}
            <div className='custom-values'>
                <div className='value-header'>
                    Tuotteet
                </div>
                <table className='product-table'>
                    <thead><tr>{data.headers}</tr></thead>
                    <tbody>{data.content}</tbody>
                    <tfoot><tr><td>Yhteensä</td>{totalRow}<td>{data.total.toFixed(2)} €</td></tr></tfoot>
                </table>
            </div>
        </div>
    </div>)
}






