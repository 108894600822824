import { Component } from "react";
import { Months } from '../monthly-view';

import '../css/data-query.css';
import { CustomerPrices, Customers, EmployeesData, MonthlyTasks, Tasks } from "../utils/data-types";
import { matchMeasurement, sanityCheckData } from "../utils/utils";
import { CustomersPrices, MonthlyPricing } from "./customer-prices";

function getDate(task: any) {
    // console.log(tasks);
    if (task !== undefined && task !== 'undefined' && task.Alkoi) {
        const date = task.Alkoi.split(" ")[0].split(".");
        const formated = date[1] + "." + date[2];
        return formated;
    }
    return null;
}

export function groupByDate(tasks: any) {
    const dated: { [key: string]: any[] } = {};
    tasks.forEach((task: any) => {
        if (!(getDate(task) in dated)) {
            dated[getDate(task)] = [];
        }
        dated[getDate(task)].push(task);
    });
    return dated;
}

export function groupByCustomerTask(tasks: any) {
    let customerSpecified: { [key: string]: any[] } = {};
    tasks.forEach((task: any) => {
        if (task.Tuotteet) {
            task.Tuotteet.forEach((t: any) => {

                if (t.includes(" m3 ")) {
                    let splitted = t.split(" m3 ");
                    splitted[0] = sanityCheckData(splitted[0]);
                    if (!(splitted[0] + " (m3)" in customerSpecified)) {
                        customerSpecified[splitted[0] + " (m3)"] = [];
                    }
                    customerSpecified[splitted[0] + " (m3)"].push(parseInt(splitted[1].split("m3")[0]));

                } else if (t.includes(" tuntityö ")) {
                    let splitted = t.split(" tuntityö ");
                    splitted[0] = sanityCheckData(splitted[0]);
                    if (!(splitted[0] + " (h)" in customerSpecified)) {
                        customerSpecified[splitted[0] + " (h)"] = [];
                    }
                    customerSpecified[splitted[0] + " (h)"].push(parseInt(splitted[1].split("h")[0]));
                } else {
                    const regexed = matchMeasurement(t);
                    if (regexed) {
                        if (!(regexed.header in customerSpecified)) {
                            customerSpecified[regexed.header] = [];
                        }
                        customerSpecified[regexed.header].push(regexed.amount);

                    } else {
                        if (!(t in customerSpecified)) {
                            customerSpecified[t] = [];
                        }
                        customerSpecified[t].push(t);
                    }
                }
            });
        }
    });
    return customerSpecified;
}

function groupByCustomer(taskData: any): Customers {
    let customers: Customers = {};
    taskData.forEach((task: any) => {
        if (!(task.Asiakas in customers)) {
            customers[task.Asiakas] = { "tasks": [], "grouped": {} };
        }
        customers[task.Asiakas].tasks.push(task);
    });

    return customers;
}

export function createEmployeeData(jsonarray: any): EmployeesData {
    var employeeData: EmployeesData = {};

    jsonarray.forEach((task: any) => {
        if (!(task.Tyontekija in employeeData)) {
            employeeData[task.Tyontekija] = {};

        }
        if (!(getDate(task) in employeeData[task.Tyontekija])) {
            employeeData[task.Tyontekija][getDate(task)] = { tasks: [], customer: {} };
        }
        // console.log(task);
        employeeData[task.Tyontekija][getDate(task)]["tasks"].push(task);
    });

    for (const [date, dateContainer] of Object.entries(employeeData)) {
        for (const [key, dateContent] of Object.entries(dateContainer)) {
            dateContent.customer = groupByCustomer(dateContent.tasks);
        }
    }

    for (const [date, dateContainer] of Object.entries(employeeData)) {
        for (const [key, dateContent] of Object.entries(dateContainer)) {
            for (const [customerName, customerData] of Object.entries(dateContent.customer!)) {
                customerData["grouped"] = groupByCustomerTask(customerData.tasks!);
            }
        }
    }
    return employeeData;
}

export function DisplayEmployees(data: EmployeesData, index: number, customerPrices: CustomerPrices, pricing: MonthlyPricing, removeTaskIds: number[], group:boolean, groupAll: boolean, monthlyCosts?: MonthlyCosts, updateMontlyCosts?: CallableFunction, removetask?: CallableFunction) {
    const result = [];
    if (groupAll){
        const groupedData: MonthlyTasks = { "Ryhmitetty": { customer: {}, tasks: [] } };
        for (const employee in data){
            groupTasks(groupedData, data[employee]);
        }
        result.push(DisplaySingleEmployee("Kaikki työntekijät", groupedData, index, customerPrices, pricing, removeTaskIds, group, true, monthlyCosts, updateMontlyCosts, removetask));

    }else {
        for (const employee in data) {
            result.push(DisplaySingleEmployee(employee, data[employee], index, customerPrices, pricing, removeTaskIds, group,false, monthlyCosts, updateMontlyCosts, removetask));
        }
    }
    return (<div key={index} className="employee">
        {result}
    </div>)
}

function groupTasks(groupedData: MonthlyTasks, original: MonthlyTasks) {
    for (const month in original) {
        groupedData["Ryhmitetty"]["tasks"].push(...original[month].tasks);

        for (const company in original[month].customer) {
            if (!(company in groupedData["Ryhmitetty"]["customer"])) {
                groupedData["Ryhmitetty"]["customer"][company] = { "tasks": [], "grouped": {} };
            }
            groupedData["Ryhmitetty"]["customer"][company]["tasks"].push(...original[month].customer[company].tasks);
            for (const task in original[month].customer[company].grouped) {
                if (!(task in groupedData["Ryhmitetty"]["customer"][company]["grouped"])) {
                    groupedData["Ryhmitetty"]["customer"][company]["grouped"][task] = [];
                }
                groupedData["Ryhmitetty"]["customer"][company]["grouped"][task].push(...original[month].customer[company].grouped[task]);
            }
        }
    }

}

function DisplaySingleEmployee(
     employeeName: string,
     employeeTasks: MonthlyTasks,
     index: number = 0,
     customerPrices: CustomerPrices,
     pricing: MonthlyPricing,
     removeTaskIds: number[],
     group: boolean,
     groupAll: boolean,
     monthlyCosts?: MonthlyCosts,
     updateMontlyCosts?: CallableFunction,
     removeTask?: CallableFunction) {

    // console.log(monthlyCosts);
    if (group || groupAll){
        const groupedData:MonthlyTasks = {"Ryhmitetty":{customer:{},tasks:[]}};
        if (employeeName && employeeName !== "undefined") {
            groupTasks(groupedData, employeeTasks);

            const groupedMonthlys: MonthlyCosts = {};
            if (groupAll){
                groupedMonthlys[employeeName] = [{
                    employee: employeeName,
                    fullDay: 0,
                    halfDay: 0,
                    kmCompanyCar: 0,
                    kmOwnCar: 0,
                    monthlyHours: 0,
                    other: 0,
                    monthDate: "Ryhmitetty"
                }];
                for (const employee in monthlyCosts){
                    for (const month in monthlyCosts[employee]) {
                        groupedMonthlys[employeeName][0].fullDay += parseFloat(monthlyCosts[employee][month].fullDay);
                        groupedMonthlys[employeeName][0].halfDay += parseFloat(monthlyCosts[employee][month].halfDay);
                        groupedMonthlys[employeeName][0].kmCompanyCar += parseFloat(monthlyCosts[employee][month].kmCompanyCar);
                        groupedMonthlys[employeeName][0].kmOwnCar += parseFloat(monthlyCosts[employee][month].kmOwnCar);
                        groupedMonthlys[employeeName][0].monthlyHours += parseFloat(monthlyCosts[employee][month].monthlyHours);
                        groupedMonthlys[employeeName][0].other += parseFloat(monthlyCosts[employee][month].other);
                    }
                }

            }else {
                groupedMonthlys[employeeName] = [{
                    employee: employeeName,
                    fullDay: 0,
                    halfDay: 0,
                    kmCompanyCar: 0,
                    kmOwnCar: 0,
                    monthlyHours: 0,
                    other: 0,
                    monthDate: "Ryhmitetty"
                }];
                if (employeeName in monthlyCosts){
                    for(const month in monthlyCosts[employeeName]){
                        groupedMonthlys[employeeName][0].fullDay += parseFloat(monthlyCosts[employeeName][month].fullDay);
                        groupedMonthlys[employeeName][0].halfDay += parseFloat(monthlyCosts[employeeName][month].halfDay);
                        groupedMonthlys[employeeName][0].kmCompanyCar += parseFloat(monthlyCosts[employeeName][month].kmCompanyCar);
                        groupedMonthlys[employeeName][0].kmOwnCar += parseFloat(monthlyCosts[employeeName][month].kmOwnCar);
                        groupedMonthlys[employeeName][0].monthlyHours += parseFloat(monthlyCosts[employeeName][month].monthlyHours);
                        groupedMonthlys[employeeName][0].other += parseFloat(monthlyCosts[employeeName][month].other);

                    }
                }
            }

            return (
                <div className="employeeData" key={employeeName + index}>
                    <div className="employeeName">{employeeName}</div>
                    <div className="views">
                        {
                            Months(groupedData,
                                employeeName,
                                index,
                                customerPrices,
                                pricing,
                                removeTaskIds,
                                monthlyCosts && groupedMonthlys[employeeName] ? groupedMonthlys[employeeName] : undefined,
                                updateMontlyCosts,
                                removeTask)
                        }
                    </div>
                </div>);
        }
    }else {
        for (const month in employeeTasks) {
            if (employeeName && employeeName !== "undefined") {
                return (
                    <div className="employeeData" key={employeeName + index}>
                        <div className="employeeName">{employeeName}</div>
                        <div className="views">
                            {
                                Months(employeeTasks,
                                     employeeName, 
                                     index, 
                                     customerPrices, 
                                     pricing, 
                                     removeTaskIds, 
                                     monthlyCosts && monthlyCosts[employeeName] ? monthlyCosts[employeeName] : undefined, 
                                     updateMontlyCosts, 
                                     removeTask)
                            }
                        </div>
                    </div>);
            }
        }
    }
}

type EmployeeState = {
    data?: EmployeesData;
    monthlyCosts?: MonthlyCosts;
    removeTasks: number[];
    group: boolean;
    groupAll: boolean;
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
}

type EmployeeQuery = {
    employee?: string;
    startDate?: string;
    endDate?: string;
    display?: boolean;
}

export type MonthlyCosts = {
    [key: string]: EmployeeMontlyCosts[]
}

export type EmployeeMontlyCosts = {
    monthDate: string;
    employee: string;
    kmCompanyCar: any;
    kmOwnCar: any;
    halfDay: any;
    fullDay: any;
    monthlyHours: any;
    other: any;
}

export type EmployeeProps = {
    employees: string[];
    data: EmployeesData;
    pricing: MonthlyPricing;
    customerPrices: CustomerPrices;
}

export class Employees extends Component<EmployeeProps, EmployeeState> {

    constructor(props) {
        super(props);

        let perviousMonth = new Date().getMonth() - 3;
        let previousYear = new Date().getFullYear();
        if (perviousMonth < 0) {
            perviousMonth += 13;
            previousYear -= 1;
        } else {
            perviousMonth += 1;
        }

        let previousMonthString = perviousMonth.toString();
        if (previousMonthString.length === 1) {
            previousMonthString = "0" + previousMonthString;
        }

        let currentMonth = (new Date().getMonth() + 1).toString();
        if (currentMonth.length === 1) {
            currentMonth = "0" + currentMonth;
        }
        const currentYear = new Date().getFullYear();

        this.state = { removeTasks: [], group: false, groupAll: false, startMonth: previousMonthString, startYear: previousYear.toString(), endMonth: currentMonth, endYear: currentYear.toString()};
        this.showEmployeeData = this.showEmployeeData.bind(this);
        this.updateMontlyCosts = this.updateMontlyCosts.bind(this);
        this.saveMonthlyCosts = this.saveMonthlyCosts.bind(this);
        this.removeTask = this.removeTask.bind(this);
        this.updateStartMonth = this.updateStartMonth.bind(this);
        this.updateStartYear = this.updateStartYear.bind(this);
        this.updateEndMonth = this.updateEndMonth.bind(this);
        this.updateEndYear = this.updateEndYear.bind(this);
    }

    showEmployeeData() {
        const startDate = (document.getElementById("start-date") as HTMLSelectElement);
        const startYear = (document.getElementById("start-year") as HTMLSelectElement);
        const endDate = (document.getElementById("end-date") as HTMLSelectElement);
        const endYear = (document.getElementById("end-year") as HTMLSelectElement);
        const nameOption = (document.getElementById("employee-name") as HTMLSelectElement);
        const temp: EmployeeQuery = {
            employee: nameOption.options[nameOption.selectedIndex].value,
            startDate: startYear.options[startYear.selectedIndex].value+"-"+startDate.options[startDate.selectedIndex].value+"-01",
            endDate: endYear.options[endYear.selectedIndex].value + "-" + endDate.options[endDate.selectedIndex].value + "-01",
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset="utf-8"' },
            body: JSON.stringify(temp),
        };
        fetch('server/get-tasks.php', requestOptions)
            .then(response => response.json())
            .then(data => { /*console.log(data);*/ this.setState(
                { 
                    data:createEmployeeData(data.tasks), 
                    monthlyCosts: data.monthlyCosts,
                    removeTasks: []
                })});
    }

    public updateMontlyCosts(employeeName: string, date: string, param: string, value: string) {
        const temp = this.state;
        if (!(employeeName in temp.monthlyCosts)){
            temp.monthlyCosts[employeeName] = []
        }
        for (const monthlys in temp.monthlyCosts[employeeName]){
            if (date === temp.monthlyCosts[employeeName][monthlys].monthDate){
                temp.monthlyCosts[employeeName][monthlys][param] = value;
                this.setState(temp);
                return;
            }
        }
        const newMonth = {
            monthDate: date,
            employee: employeeName,
            kmCompanyCar: 0,
            kmOwnCar: 0,
            halfDay: 0,
            fullDay: 0,
            monthlyHours: 0,
            other: 0,
        };
        newMonth[param] = value;
        temp.monthlyCosts[employeeName].push(newMonth);
        this.setState(temp);
    }

    public saveMonthlyCosts() {
        const costs = [];
        for (const emp in this.state.monthlyCosts){
            costs.push(...this.state.monthlyCosts[emp]);
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset="utf-8"' },
            body: JSON.stringify({monthlyCosts: costs, removeTasks: this.state.removeTasks}),
        };
        fetch('server/insert-monthly-costs.php', requestOptions)
            .then(response => response.json())
            .then(data => {console.log(data); if(this.state.removeTasks.length > 0){
                this.showEmployeeData();
            } });

    }

    public removeTask(taskId: number) {
        const temp = this.state;
        if (temp.removeTasks.indexOf(taskId) !== -1){
            temp.removeTasks.splice(temp.removeTasks.indexOf(taskId));
        }else {
            temp.removeTasks.push(taskId);
        }
        this.setState(temp);
    }

    updateStartMonth(event) {
        var newState: EmployeeState = {
            data: this.state.data,
            monthlyCosts: this.state.monthlyCosts,
            removeTasks: this.state.removeTasks,
            group: this.state.group,
            groupAll: this.state.groupAll,
            startMonth: event.target.value,
            startYear: this.state.startYear,
            endMonth: this.state.endMonth,
            endYear: this.state.endYear
        };
        this.setState(newState);
    }

    updateStartYear(event) {
        var newState: EmployeeState = {
            data: this.state.data,
            monthlyCosts: this.state.monthlyCosts,
            removeTasks: this.state.removeTasks,
            group: this.state.group,
            groupAll: this.state.groupAll,
            startMonth: this.state.startMonth,
            startYear: event.target.value,
            endMonth: this.state.endMonth,
            endYear: this.state.endYear
        };
        this.setState(newState);
    }

    updateEndMonth(event) {
        var newState: EmployeeState = {
            data: this.state.data,
            monthlyCosts: this.state.monthlyCosts,
            removeTasks: this.state.removeTasks,
            group: this.state.group,
            groupAll: this.state.groupAll,
            startMonth: this.state.startMonth,
            startYear: this.state.startYear,
            endMonth: event.target.value,
            endYear: this.state.endYear
        };
        this.setState(newState);
    }

    updateEndYear(event) {
        var newState: EmployeeState = {
            data: this.state.data,
            monthlyCosts: this.state.monthlyCosts,
            removeTasks: this.state.removeTasks,
            group: this.state.group,
            groupAll: this.state.groupAll,
            startMonth: this.state.startMonth,
            startYear: this.state.startYear,
            endMonth: this.state.endMonth,
            endYear: event.target.value
        };
        this.setState(newState);
    }

    public render() {

        return (<div>
            <div className="data-query">
                <div className="employee-selection">
                    <div>
                        <select id="employee-name">
                            <option key="all-employees" value="all">Kaikki työntekijät</option>
                            {this.props.employees.map((name) => { return (<option key={name} value={name}>{name}</option>); })}
                        </select>
                    </div>
                    <div className="dates">
                        <select id="start-date" value={this.state.startMonth} onChange={this.updateStartMonth}>
                            <option value="01">Tammikuu</option>
                            <option value="02">Helmikuu</option>
                            <option value="03">Maaliskuu</option>
                            <option value="04">Huhtikuu</option>
                            <option value="05">Toukokuu</option>
                            <option value="06">Kesäkuu</option>
                            <option value="07">Heinäkuu</option>
                            <option value="08">Elokuu</option>
                            <option value="09">Syyskuu</option>
                            <option value="10">Lokakuu</option>
                            <option value="11">Marraskuu</option>
                            <option value="12">Joulukuu</option>
                        </select>
                        <select id="start-year" value={this.state.startYear} onChange={this.updateStartYear}>
                            <option>2021</option>
                            <option>2022</option>
                            <option>2023</option>
                        </select>
                        <span>-</span>
                        <select id="end-date" value={this.state.endMonth} onChange={this.updateEndMonth}>
                            <option value="01">Tammikuu</option>
                            <option value="02">Helmikuu</option>
                            <option value="03">Maaliskuu</option>
                            <option value="04">Huhtikuu</option>
                            <option value="05">Toukokuu</option>
                            <option value="06">Kesäkuu</option>
                            <option value="07">Heinäkuu</option>
                            <option value="08">Elokuu</option>
                            <option value="09">Syyskuu</option>
                            <option value="10">Lokakuu</option>
                            <option value="11">Marraskuu</option>
                            <option value="12">Joulukuu</option>
                        </select>
                        <select id="end-year" value={this.state.endYear} onChange={this.updateEndYear}>
                            <option>2021</option>
                            <option>2022</option>
                            <option>2023</option>
                        </select>
                    </div>
                    <div className="checkbox">
                        <label className="group-header">Ryhmitä työntekijät:</label>
                        <input type="checkbox" checked={this.state.group} onChange={(e)=>{this.setState({
                            data: this.state.data,
                            monthlyCosts: this.state.monthlyCosts,
                            removeTasks: this.state.removeTasks,
                            group: e.target.checked,
                            groupAll: this.state.groupAll,
                            startMonth: this.state.startMonth,
                            startYear: this.state.startYear,
                            endMonth: this.state.endMonth,
                            endYear: this.state.endYear
                        })}}/>
                    </div>
                    <div className="checkbox">
                        <label className="group-header">Ryhmitä kaikki:</label>
                        <input type="checkbox" checked={this.state.groupAll} onChange={(e) => {
                            this.setState({
                                data: this.state.data,
                                monthlyCosts: this.state.monthlyCosts,
                                removeTasks: this.state.removeTasks,
                                group: this.state.group,
                                groupAll: e.target.checked,
                                startMonth: this.state.startMonth,
                                startYear: this.state.startYear,
                                endMonth: this.state.endMonth,
                                endYear: this.state.endYear
                            })
                        }}/>
                    </div>
                    <div>
                        <button onClick={this.showEmployeeData} className="search">Näytä</button>
                    </div>
                    {this.state.data ?
                        <div>
                            <button onClick={this.saveMonthlyCosts} className="search">Tallenna</button>
                        </div>
                        : ''}
                </div>
                <div>
                    {this.state.data ? DisplayEmployees(
                        this.state.data, 
                        0,
                        this.props.customerPrices,
                        this.props.pricing,
                        this.state.removeTasks,
                        this.state.group,
                        this.state.groupAll,
                        this.state.monthlyCosts,
                        this.updateMontlyCosts,
                        this.removeTask
                        ) : <></>}
                </div>
            </div>

        </div>);
    }

}