import { Component } from "react";
import { loginDataParsed } from "../utils/utils";
import { MonthlyPricing } from "./customer-prices";

type LoginProps = {
    validate: CallableFunction
}

type LoginCredentials = {
    email: string;
    password: string;
    loginStatus: string;
    validatePasswordMain: string;
    validatePasswordSecond: string;
}

export type LoginResult = {
    username: string;
    customerPrices: any;
    employees: string[];
    taskNames: string;
    monthlyPrices: MonthlyPricing;
    customerNames: string[];
}

export class LoginPage extends Component<LoginProps, LoginCredentials> {
    state: LoginCredentials;
    constructor(props) {
        super(props);
        this.state = { email: '', password: '', loginStatus: '', validatePasswordSecond:'', validatePasswordMain:'' };
        this.login = this.login.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.validateLogin = this.validateLogin.bind(this);
        this.handleValidationPassword = this.handleValidationPassword.bind(this);
        this.handleValidationPasswordMain = this.handleValidationPasswordMain.bind(this);
        this.matchPasswords = this.matchPasswords.bind(this);
        this.setPassword = this.setPassword.bind(this);
    }

    handleEmail(event) {
        const tempState = this.state;
        tempState.email = event.target.value;
        this.setState(tempState);
    }

    handlePassword(event) {
        const tempState = this.state;
        tempState.password = event.target.value;
        this.setState(tempState);
    }

    handleValidationPasswordMain(event) {
        const tempState = this.state;
        tempState.validatePasswordMain = event.target.value;
        this.setState(tempState, () => this.matchPasswords());
    }

    handleValidationPassword(event) {
        const tempState = this.state;
        tempState.validatePasswordSecond = event.target.value;
        this.setState(tempState, () => this.matchPasswords());
    }

    matchPasswords() {
        (document.getElementById('submit-password') as HTMLButtonElement).disabled = !(this.state.validatePasswordMain === this.state.validatePasswordSecond);
    }

    login(event) {
        event.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: this.state.email, pwd: this.state.password }),
        };
        fetch('server/login.php', requestOptions)
            .then(response => response.json())
            .then(data => this.validateLogin(data));
    }

    setPassword(event){
        event.preventDefault();
        // console.log(this.state);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: this.state.email, pwd: this.state.validatePasswordMain }),
        };
        fetch('server/update-user.php', requestOptions)
            .then(response => response.json())
            .then(data => this.validateLogin(data));
    }

    validateLogin(data):void {
        // console.log(data, "login data");

        if (data.status === 'success'){
            this.props.validate(loginDataParsed(this.state.email, data));
        }else if (data.status === 'missing-password'){
            const temp = this.state;
            temp.loginStatus = 'missing-password';
            this.setState(temp);
        }else {
            const temp = this.state;
            temp.loginStatus = 'Virheellinen käyttäjätunnus tai salasana';
            this.setState(temp);
        }
    }

    loginForm() {
        return <form onSubmit={this.login}>
            <div className="row">
                <label>Sähköposti: </label>
                <br />
                <input type="text" name="name" value={this.state.email} onChange={this.handleEmail} />
            </div>
            <div className="row">
                <label>Salasana:</label>
                <br />
                <input type="password" name="password" value={this.state.password} onChange={this.handlePassword} />
            </div>
            <div className="row">
                {this.state.loginStatus}
            </div>
            <div className="row">
                <input type="submit" value="Submit" />
            </div>
        </form>;
    }

    setPasswordForm(){
        return <form onSubmit={this.setPassword}>
            <div className="row">
                Aseta uusi salasana:
            </div>
            <div className="row">
                <label>Salasana: </label>
                <br />
                <input type="password" name="password" value={this.state.validatePasswordMain} onChange={this.handleValidationPasswordMain} />
            </div>
            <div className="row">
                <label>Salasana uudestaan:</label>
                <br />
                <input type="password" name="validate-password" value={this.state.validatePasswordSecond} onChange={this.handleValidationPassword} />
            </div>
            <div className="row">
                <input type="submit" value="Submit" id="submit-password" />
            </div>
        </form>
    }

    render() {
        return (
            <div className="LoginPage">
                <div className="Login">
                    {this.state.loginStatus === 'missing-password' ?  this.setPasswordForm() : this.loginForm()}
                </div>
            </div>);
    }
}
