import { MonthlyPricing } from "../pages/customer-prices";
import { LoginResult } from "../pages/login";

export function splitCSV(csvData: string, deliminator: string) {
    const data = csvData.split(/\r?\n/);
    data.forEach((line, index) => {
        data[index] = replaceDeliminators(line, deliminator);
    });
    var result = createJson(data, deliminator);

    // console.log(result);
    // createTable(filename, result);
    return Sanitize(result);
}

function replaceDeliminators(line: string, deliminator: string) {
    let ind = line.indexOf('"');
    while (ind !== -1) {
        let scnd = line.indexOf('"', (ind + 1));
        let cm = line.indexOf(deliminator, (ind + 1));
        while (cm !== -1 && cm < scnd) {
            line = line.substr(0, cm) + "." + line.substr(cm + 1);
            cm = line.indexOf(deliminator, (ind + 1));
        }
        ind = line.indexOf('"', scnd + 1);
    }
    return line;
}

function createJson(data: string[], deliminator: string) {
    const result = []
    data[0] = data[0]
        .replaceAll(/\u00f6/g, 'o')
        .replaceAll(/\u00e4/g, 'a')
        .replaceAll(/\u00c4/g, 'A')
        .replaceAll(/\u00d6/g, 'O');

    const header = data[0].split(deliminator);
    for (let i = 1; i < data.length; i++) {
        const dataRow = data[i].split(deliminator);
        let line: { [key: string]: string } = {};
        for (let j = 0; j < dataRow.length; j++) {
            line[header[j].trim()] = dataRow[j]
                .replaceAll('"', '')
                .replaceAll("\t", " ")
                .replace(/ +(?= )/g, '');
        }
        result.push(line);
    }
    return result;
}

export function sanityCheckData(task: string) {
    return task.replace(/,\s*$/, "").replace(/^A /, '');;
}

type RegExpValue = {
    header: string;
    amount: number;
}

export function matchMeasurement(task: string): RegExpValue {
    let result;
    [
        { m: "h", reg: new RegExp(/[0-9]+h/) },
        { m: "ha", reg: new RegExp(/[0-9]+\.[0-9]+ha|[0-9]+ha/) },
        { m: "kpl", reg: new RegExp(/[0-9]+kpl/) },
        { m: "m3", reg: new RegExp(/[0-9]+m3/) },
        { m: "km", reg: new RegExp(/[0-9]+km/) },
    ].forEach(mm => {
        if (mm.reg.test(task)) {
            // console.log("Match for ", task)
            // const getNumber = new RegExp()
            result =
            {
                header: task.replace(mm.reg, '').replace(/\s*$/, '') + ' (' + mm.m + ')',
                amount: parseFloat(task.match(mm.reg).reduce((a, b) => a + b, '').replace(mm.m, '')),
            };
        };
    });

    return result;
}

function Sanitize(tasks: any[]) {
    let remove = [];
    tasks.forEach((task: any, index) => {
        if (task.Tuotteet) {
            let isHarv = false;
            let isAukko = false;
            let isMissing = false;
            task.Tuotteet = task.Tuotteet.split("Purku")
                .map((item: any) => item.trim().replace(/aukko\s/, 'Aukko ').replace(/harv\s/, 'Harv '))
                .filter((item: any) => item !== '' && item !== '-' && item !== undefined);
            task.Tuotteet.map((item: string) => {
                if (item.match(/aukko\s/ig)) {
                    isAukko = true;
                }
                if (item.match(/harv\s/ig)) {
                    isHarv = true;
                }
                if (!(item.match(/aukko\s/ig)) && !(item.match(/harv\s/ig))) {
                    isMissing = true;
                }
            });
            if (isAukko && isHarv && isMissing) {
                console.log("Task list contaisn aukko, harv and is missing one without either", task.Tuotteet);
                throw new Error("Task list contaisn aukko, harv and is missing one without either");
            } else if (isMissing && (isHarv || isAukko)) {
                for (let i = 0; i < task.Tuotteet.length; i++) {
                    if (isAukko && !(task.Tuotteet[i].match(/aukko\s/ig))) {
                        task.Tuotteet[i] = "Aukko " + task.Tuotteet[i];
                    } else if (isHarv && !(task.Tuotteet[i].match(/harv\s/ig))) {
                        task.Tuotteet[i] = "Harv " + task.Tuotteet[i];
                    }
                }
            }
        }
        else {
            remove.push(index);
        }
    });
    remove.sort().reverse();
    for (let i = 0; i < remove.length; i++){
        tasks.splice(remove[i], 1);
    }


    return tasks;
}

export function loginDataParsed(email: string, data: any): LoginResult{
    const customerPrices = {};

    if (!!data.customers){
        for (const customer in data.customers) {
            customerPrices[customer] = JSON.parse(data.customers[customer]);
        }
    }

    const monthyPrices: MonthlyPricing = !!data.monthlyPrices ? JSON.parse(data.monthlyPrices) : {kmCompanyCar:'0', kmOwnCar:'0',fullDay:'0',halfDay:'0',monthlyHours:'0'};

    return ({ 
        username: email, 
        customerPrices: customerPrices, 
        employees: data.employees,
        taskNames: data.taskNames,
        monthlyPrices: monthyPrices,
        customerNames: data.customerNames });

}