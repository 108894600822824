import React, { Component } from 'react';
import './App.css';
import { InputBox } from './inputcontainer';
import { CustomersPrices, MonthlyPricing } from './pages/customer-prices';
import { Customers } from './pages/customers';
import { Employees } from './pages/employee';
import { LoginPage, LoginResult } from './pages/login';
import { ErpPages, NavigationBar } from './pages/navigation';
import { CustomerContent, CustomerPrices, EmployeesData } from './utils/data-types';
import { loginDataParsed } from './utils/utils';

type SiteState = {
  username?: string;
  currentPage: ErpPages;
  customerData: CustomerContent;
  monthlyPricing: MonthlyPricing;
  employeeData: EmployeesData;
  employees: string[],
  taskNames: string[];
  customerNames: string[];
}

class App extends Component {
  state: SiteState;

  constructor(props) {
    super(props);
    this.state = {
      username: null,
      currentPage: ErpPages.login,
      customerData: { tasks: new Set(), prices: {} },
      employeeData: {},
      employees: [],
      taskNames: [],
      customerNames: [],
      monthlyPricing : {kmOwnCar:'0',kmCompanyCar:'0',fullDay:'0',halfDay:'0',monthlyHours:'0'}
    };
    // this.state = {
    //   username: 'null',
    //   currentPage: ErpPages.input,
    //   customerData: { tasks: new Set(), prices: {} },
    //   employeeData: {},
    //   employees: [],
    //   taskNames: [],
    //   customerNames: [],
    //   monthlyPricing: { kmOwnCar: '0', kmCompanyCar: '0', fullDay: '0', halfDay: '0', monthlyHours: '0' }
    // };
    this.handleLogin = this.handleLogin.bind(this);
    this.checkLoginStatus = this.checkLoginStatus.bind(this);
    this.setNewPage = this.setNewPage.bind(this);

    this.updateTasks = this.updateTasks.bind(this);
    this.updatePrices = this.updatePrices.bind(this);
    this.updateEmployeeData = this.updateEmployeeData.bind(this);
    this.saveCustomerPrices = this.saveCustomerPrices.bind(this);
    this.updateMonthlyPricing = this.updateMonthlyPricing.bind(this);

    this.checkLoginStatus();
  }

  checkLoginStatus() {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset="utf-8"' },
      body: JSON.stringify({status:'login'}),
    };
    fetch('server/login-status.php', requestOptions)
      .then(response => response.json())
      .then(data => this.handleLogin(loginDataParsed(data.username, data)));

  }

  public handleLogin(result: LoginResult) {
    if (result.username === null || result.username === 'null') return;
    // console.log(result, "login result");

    const temp = this.state;
    temp.username = result.username;
    if (temp.username) temp.currentPage = ErpPages.input;
    temp.customerData.prices = result.customerPrices;
    temp.employees = result.employees.sort();
    temp.taskNames = !!result.taskNames ? JSON.parse(result.taskNames).sort() : [];
    temp.monthlyPricing = result.monthlyPrices;
    temp.customerNames = result.customerNames.sort();
    this.setState(temp);
  }

  public setNewPage(newPage: ErpPages) {
    const temp = newPage === ErpPages.logout ?
      {
        username: null,
        currentPage: ErpPages.login,
        customerData: { tasks: new Set(), prices: {} },
        employeeData: {},
        employees: [],
        customers: [],
        taskNames: [],
        monthlyPricing: { kmOwnCar: '0', kmCompanyCar: '0', fullDay: '0', halfDay: '0', monthlyHours: '0' }
      } 
      : this.state;
    if (newPage !== ErpPages.logout){
      temp.currentPage = newPage;
    }
    this.setState(temp);
  }

  public updateMonthlyPricing(type: string, price: string) {
    const temp = this.state;
    temp.monthlyPricing[type] = price.replace(",", ".");
    this.setState(temp);
  }

  public updateTasks(prices: CustomerPrices, tasks: Set<string>) {
    const temp = this.state;
    temp.customerData.tasks = tasks;
    temp.customerData.prices = prices;
    this.setState(temp);
  }

  public updatePrices(customer: string, task: string, price: number) {
    const temp = this.state;
    if (!(customer in temp.customerData.prices) 
      || (customer in temp.customerData.prices && Array.isArray(temp.customerData.prices[customer]))) {
      temp.customerData.prices[customer] = {};
    }
    temp.customerData.prices[customer][task] = price;
    this.setState(temp);
  }

  public saveCustomerPrices(): void {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset="utf-8"' },
      body: JSON.stringify({ customerData: this.state.customerData.prices, monthlyPrices: this.state.monthlyPricing }),
    };
    fetch('server/insert-customerPrices.php', requestOptions)
      .then(response => response.json());
      // .then(data => console.log(data));
  }

  public updateEmployeeData(data: EmployeesData) {
    const temp = this.state;
    temp.employeeData = data;
    this.setState(temp);
  }

  render() {
    return (
      <div className="App">
        {this.state.username
          ? <NavigationBar currentPage={this.state.currentPage} changePage={this.setNewPage} />
          : <LoginPage validate={this.handleLogin} />}
        {this.state.currentPage === ErpPages.input
          ? <InputBox
            updateTasks={this.updateTasks}
            tempData={this.updateEmployeeData}
            tasks={this.state.customerData.tasks}
            prices={this.state.customerData.prices} 
            pricing={this.state.monthlyPricing}
            />
          : <></>}
        {this.state.currentPage === ErpPages.prices ? <CustomersPrices 
            customerData={this.state.customerData} 
            updatePrices={this.updatePrices} 
            saveCustomerPrices={this.saveCustomerPrices} 
            taskNames={this.state.taskNames} 
            updateMonthlyPrices={this.updateMonthlyPricing}
            monthlyPricing={this.state.monthlyPricing}
            customerNames={this.state.customerNames}
            /> : <></>}
        {this.state.currentPage === ErpPages.employees ? <Employees data={this.state.employeeData} employees={this.state.employees} pricing={this.state.monthlyPricing} customerPrices={this.state.customerData.prices}/> : <></>}
        {this.state.currentPage === ErpPages.customers ? <Customers customerData={this.state.customerData} customerNames={this.state.customerNames}/> : <></>}
      </div>
    );
  }
}

export default App;
