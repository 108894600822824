import { Component } from "react";
import { CustomerContent, CustomerPrices } from "../utils/data-types";
import '../css/customer-prices.css';
import '../css/data-query.css';

export type MonthlyPricing = {
    kmCompanyCar: string;
    kmOwnCar: string;
    halfDay: string;
    fullDay: string;
    monthlyHours: string;
}

export type CustomerProps = {
    customerData: CustomerContent;
    updatePrices: CallableFunction;
    saveCustomerPrices: CallableFunction;
    taskNames: string[];
    monthlyPricing: MonthlyPricing;
    updateMonthlyPrices: CallableFunction;
    customerNames: string[];
}

export class CustomersPrices extends Component<CustomerProps, CustomerPrices> {
    constructor(props) {
        super(props);
    }

    public render() {

        const taskHeaders = this.props.customerData.tasks;
        for (const customer in this.props.customerData.prices){
            for (const task in this.props.customerData.prices[customer]){
                taskHeaders.add(task);
            }
        }

        const headers = [<td key="customer-name"></td>]
        for (const task in this.props.taskNames) {
            headers.push(<td key={'head-' + this.props.taskNames[task]}>{this.props.taskNames[task]}</td>);
        }

        const customerData = [];
        const customerPrices = this.props.customerData.prices;
        for (const customerName in this.props.customerNames) {
            const customer = this.props.customerNames[customerName];
            if (!(customer in customerPrices)){
                customerPrices[customer] = {};
            }
            const cp = [];
            for (const task in this.props.taskNames) {
                if (this.props.taskNames[task] in customerPrices[customer]) {
                    cp.push(<td><input className="user-input" onChange={(e) => this.props.updatePrices(customer, this.props.taskNames[task], e.target.value.replace(',', '.'))} value={customerPrices[customer][this.props.taskNames[task]]} /></td>)
                } else {
                    cp.push(<td><input className="user-input" onChange={(e) => this.props.updatePrices(customer, this.props.taskNames[task], e.target.value.replace(',', '.'))} /></td>);
                }
            }
            customerData.push(<tr className="customer-data-row"><td className="customer-name">{customer}</td>{cp}</tr>)
        }


        return (<div className="customer-pricing">
            <table>
                <thead className="tasks"><tr>{headers}</tr></thead>
                <tbody>{customerData}</tbody>
            </table>
            <div className="pricing">
                <table className="tasks">
                    <thead>
                        <tr>
                            <td></td><td>Hinta</td>
                        </tr>
                    </thead>
                    <tbody>
                            <tr><td>Km firman auto</td><td><input value={this.props.monthlyPricing.kmCompanyCar} onChange={(e)=>this.props.updateMonthlyPrices('kmCompanyCar', e.target.value)} className='user-input' /></td></tr>
                            <tr><td>Km oma auto</td><td><input value={this.props.monthlyPricing.kmOwnCar} onChange={(e)=>this.props.updateMonthlyPrices('kmOwnCar', e.target.value)} className='user-input' /></td></tr>
                            <tr><td>Puolipäiväraha	</td><td><input value={this.props.monthlyPricing.halfDay} onChange={(e)=>this.props.updateMonthlyPrices('halfDay', e.target.value)} className='user-input' /></td></tr>
                            <tr><td>Kokopäiväraha	</td><td><input value={this.props.monthlyPricing.fullDay} onChange={(e)=>this.props.updateMonthlyPrices('fullDay', e.target.value)} className='user-input' /></td></tr>
                            <tr><td>Kuukauden työtunnit</td><td><input value={this.props.monthlyPricing.monthlyHours} onChange={(e)=>this.props.updateMonthlyPrices('monthlyHours', e.target.value)} className='user-input' /></td></tr>
                    </tbody>
                </table>
            </div>
            <div className="row">
                <button onClick={() => this.props.saveCustomerPrices()} className="search">Tallenna</button>
            </div>
        </div>)
    }
}