import React, { Component } from "react";
import { splitCSV } from "./utils/utils";
import { CustomerPrices, EmployeesData } from "./utils/data-types";
import { createEmployeeData, DisplayEmployees } from "./pages/employee";
import './css/data-query.css';
import { MonthlyPricing } from "./pages/customer-prices";


export type InputBoxProps = {
    updateTasks: CallableFunction;
    tempData: CallableFunction;
    tasks: Set<string>;
    prices: CustomerPrices;
    pricing: MonthlyPricing;
}

type InputState = {
    employeeData: EmployeesData,
    tasks: any[]
}

export class InputBox extends Component<InputBoxProps, InputState> {

    constructor(props: any) {
        super(props);
        this.state = {
            employeeData: {},
            tasks: []
        }
        this.setStateData = this.setStateData.bind(this);
        this.saveTasks = this.saveTasks.bind(this);

        // console.log(matchMeasurement("UPM PEFC suunnittelu / ha(vanhat data) 72.8ha"));
        // console.log(matchMeasurement("Korjuutyönjohto 22822m3"));
    }

    setStateData(tasks: any[], data: EmployeesData) {
        const tempState: InputState = {
            tasks: tasks,
            employeeData: data
        };
        // console.log(tasks);
        //TODO Tasks to be saved to database
        this.updateTasks(data);
        this.setState(tempState);
        this.props.tempData(data);
    }

    saveTasks(): void {
        console.log("Save tasks", this.props);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset="utf-8"' },
            body: JSON.stringify({ tasks: this.state.tasks, taskNames: Array.from(this.props.tasks.values()).sort() }),
        };
        fetch('server/insert-tasks.php', requestOptions)
            .then(response => response.json())
            .then(data => console.log(data));
    }

    updateTasks(data: EmployeesData) {
        const tasks = this.props.tasks;
        const customers = this.props.prices;
        for (const employee in data) {
            for (const date in data[employee]) {
                for (const customer in data[employee][date].customer) {
                    if (!(customer in customers) && customer !== undefined && customer !== 'undefined') {
                        customers[customer] = {};
                    }
                    for (const task in data[employee][date].customer[customer].grouped) {
                        tasks.add(task);
                    }
                }
            }
        }
        this.props.updateTasks(customers, tasks);
    }

    render() {
        const handleDragOver = (e: any) => {
            e.preventDefault();
        }

        const handleDrop = (evt: any, updateData: CallableFunction) => {
            evt.preventDefault();
            const dT = new DataTransfer();
            for (let i = 0; i < evt.dataTransfer.files.length; i++) {
                dT.items.add(evt.dataTransfer.files[i]);

                const reader = new FileReader();
                reader.onload = function (e) {
                    var text = e!.target!.result;
                    //TODO add check if there already is data that do you want to override
                    const tasks = splitCSV(text as string, ";");
                    const employeeData = createEmployeeData(tasks);
                    updateData(tasks, employeeData);
                }
                reader.readAsText(dT.files[i], 'ISO-8859-1');
            }
        }

        return (<div className="Input">
            <div id="dropContainer"
                onDragOver={e => handleDragOver(e)}
                onDrop={e => handleDrop(e, this.setStateData)}>
                <p id="dropHere">Drop Here</p>
            </div>
            {this.state.tasks.length > 0 ? 
            <div className="row">
                <button onClick={this.saveTasks} className="search">Tallenna</button>
            </div>
            : ''}
            <div id="employeeContainer">
                {DisplayEmployees(this.state.employeeData, 0, this.props.prices, this.props.pricing, [], false, false)}
            </div>
        </div>);
    }
}