import { Component } from "react";

export enum ErpPages {
    login,
    input,
    employees,
    prices,
    customers,
    logout
}

export type NavigationProps = {
    changePage: CallableFunction;
    currentPage: ErpPages;
}

export class NavigationBar extends Component<NavigationProps> {

    newPage(page: ErpPages) {
        this.props.changePage(page);
    }

    logout() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
        };
        fetch('server/logout.php', requestOptions)
            .then(response => response.json())
            .then(data => {console.log(data); this.newPage(ErpPages.logout);});
    }

    render() {
        return <div className="nav">
            <a className={this.props.currentPage === ErpPages.input ? 'currentPage' : ''} href="#home" onClick={() => this.newPage(ErpPages.input)}>Etusivu</a>
            <a className={this.props.currentPage === ErpPages.employees ? 'currentPage' : ''} href="#employees" onClick={() => this.newPage(ErpPages.employees)}>Työntekijät</a>
            <a className={this.props.currentPage === ErpPages.prices ? 'currentPage' : ''} href="#prices" onClick={() => this.newPage(ErpPages.prices)}>Hinnasto</a>
            <a className={this.props.currentPage === ErpPages.customers ? 'currentPage' : ''} href="#customers" onClick={() => this.newPage(ErpPages.customers)}>Asiakkaat</a>
            <div className="nav-right">
                <a href="#" onClick={() => this.logout()}>Kirjaudu ulos</a>
            </div>
        </div>
    }
}